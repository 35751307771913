<template>
  <div v-loading="pdfLoading" element-loading-text="pdf导入中请勿离开...">
    <el-card>
      <el-table :data="list">
        <el-table-column label="编号" prop="id"></el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="名称" prop="title"></el-table-column>
        <el-table-column label="图片">
          <template v-slot="s">
            <img :src="s.row.file | tomedia" style="width: 3rem;height: 3rem;border-radius: 3px">
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot:header>
            <div class="flex-def">
              <el-button style="margin-right: 10px" @click="itemEdit(false)" type="primary">添加图片</el-button>
              <el-upload
                  ref="uploader"
                  :action="pdfAction"
                  :headers="{ytoken:vuex_token.data}"
                  name="file"
                  :data="{album_id:album_id}"
                  :show-file-list="false"
                  accept="application/pdf"
                  :before-upload="pdfBeforeUpload"
                  :on-success="pdfUploadSuccess"
                  :on-error="pdfUploadError"
                  :multipl="false"
              >
                <el-button type="primary">PDF导入</el-button>
              </el-upload>
            </div>
          </template>
          <template v-slot="s">
            <el-button @click="itemEdit(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
        <template v-slot:append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="size" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
      <el-dialog title="编辑视频" :visible.sync="formBox" @close="editFormClose">
        <el-form label-width="5rem" style="padding: 0 2rem">
          <el-form-item label="排序">
            <el-input-number v-model="form.sort"></el-input-number>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <YUploadImg v-model="form.file"></YUploadImg>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="formBox = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import YUploadImg from "@/components/y-form/y-upload/y_upload_img";
import {requestWithUni} from "@/common/resquest";
/**
 * 画册图片管理
 * 手动上传 修改
 * pdf 上传导入
 */
export default {
  name: "img-list",
  components: {YUploadImg},
  data(){
    return{
      page:1,
      total:0,
      size:0,
      list:[],

      form:{
        sort:0,
        album_id: 0,
        title:"",
        file:"",
      },
      formBox:false,

      album_id:0,
      pdfAction:"",
      pdfLoading:false,
    }
  },
  mounted() {
    this.pdfAction = requestWithUni(this.axios.defaults.baseURL + this.$u.api.album.imgPdfImportAction);
    this.album_id = parseInt(this.$route.query.album_id);
    this.load();
  },
  methods:{
    editFormClose(){
      this.form = {
        sort:0,
        album_id: 0,
        title:"",
        file:"",
      }
    },
    itemEdit(item=false){
      this.formBox = true;
      if (item)this.form = {...item}
    },
    pageChange(e){
      this.page = e;
    },
    load(){
      this.$u.api.album.imgSearch({
        page:this.page,
        album_id: this.album_id
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
    edit(){
      this.form.album_id = this.album_id;
      this.$u.api.album.imgEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.formBox = false;
        this.load();
      })
    },
    del({id}){
      this.$u.api.album.imgDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    pdfBeforeUpload(){
      this.pdfLoading = true;
    },
    pdfUploadSuccess(e){
      this.pdfLoading = false;
      this.$refs.uploader.clearFiles();
      if (e.errno !== 0){
        this.$message.error(e.message);
        return
      }
      this.$message.success(`成功导入${e.data.num}张图片`)
      this.load();
    },
    pdfUploadError(e){
      this.pdfLoading = false;
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>